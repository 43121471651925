.hashtags {
  &__wrapper {
    display: flex;
    margin-right: 15px;
    flex-direction: row;
    background-color: #f9fafb;
    border: 1px solid #eaecf0;
    border-radius: 4px;
    box-shadow: 0 4px 8px -2px rgb(16 24 40 / 10%), 0 2px 4px -2px rgb(16 24 40 / 6%);
    cursor: pointer;
    margin-bottom: 10px;
    padding: 2px 10px;
  }
  img {
    margin-right: 5px;
  }
}
