.wrapper {
  display: flex;
}

.hue{
  position: relative;
  width: 40px;
  height: 211px;
  border-radius: 24px;
  margin-left: 10px;

  div{
    border-radius: 24px;
  }

  .hue-vertical{
    border-radius: 50%;
  }
}

.test{
border-radius: 50%}

.saturation {
  width: 100%;
  height: 211px;
  position: relative;

  div {
    border-radius: 8px;
  }
}

.picker {
  width: 24px;
  height: 24px;
  border-radius: 50% !important;
  border: 3px solid #fff;
  box-shadow: 0 0 3px 0 rgba(0,0,0,0.23);
  transform: translate(-12px, -12px);
}
.hue_picker {
  width: 24px;
  height: 24px;
  border-radius: 50% !important;
  border: 3px solid #fff;
  transform: translate(7px, -12px);
}