.button {
  font-size: 14px;
  line-height: 18px;
  cursor: pointer;
  border-radius: 8px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;

  &.default {
    padding: 10px;
    color: #fff;
    border: 1px solid var(--color-primary-5);
    background-color: var(--color-primary-5);
    outline: none;
  }

  &.bordered {
    padding: 10px;
    background-color: #fff;
    color: var(--color-grey-7);
    border: 1px solid var(--color-grey-3);
  }

  &.text {
    text-decoration: none;
    color: var(--color-primary-6);
    display: inline;
    background-color: transparent;
    outline: none;
    border: none;
  }

  &.text-grey {
    text-decoration: none;
    color: var(--color-grey-5);
    display: flex;
    background-color: transparent;
    outline: none;
    border: none;
  }

  &.text-dark {
    text-decoration: none;
    color: var(--color-grey-7);
    display: flex;
    background-color: transparent;
    outline: none;
    border: none;
  }

  &.red {
    padding: 10px;
    color: #fff;
    border: 1px solid var(--color-red-5);
    background-color: var(--color-red-5);
  }

  &:disabled {
    background-color: var(--color-grey-3);
  }

}
