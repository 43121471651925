.page {
  padding: 30px;
  height: 100vh;

  h2 {
    font-size: 32px;
    font-weight: 500;
    margin-bottom: 24px;
  }
}

.controls {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;

  .filters {
    display: flex;
    align-items: center;
    div {
      &:first-child {
        min-width: 250px;
        margin-right: 32px;
      }
    }
  }

  .buttons {
    display: flex;
    align-items: center;

    button {
      svg {
        max-width: 20px;
        max-height: 20px;
        margin-right: 8px;
      }
      &:not(:first-child) {
        margin-left: 32px;
      }
    }
  }
}

.content {
  width: 100%;
  max-height: calc(100% - 136px);
  min-height: 50vh;
  padding: 16px;
  overflow: auto;
  border-radius: 16px;
  background-color: var(--color-grey-05);
}

.modalHeaderIcon {
  .iconWrapper {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: var(--color-primary-1);
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05), 0 0 0 6px rgba(191, 217, 254, 0.2);
    margin-bottom: 16px;
  }
}

.modalHeader {
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 8px;
}

.modalDescription {
  max-width: 440px;
  font-size: 14px;
  color: var(--color-grey-5);
  margin-bottom: 16px;
}

.multiInput {
  margin-bottom: 32px;
}

.csvUploader {
  margin-bottom: 32px;
}

.modalButtons {
  display: flex;
  margin: 0 auto;
  justify-content: center;

  button {
    width: 100%;

    svg {
      margin-right: 8px;
    }

    &:last-child {
      margin-left: 16px;
    }
  }
}

.infoBlock {
  padding: 16px;
  width: 100%;
  border: 1px solid var(--color-grey-2);

  &:not(:last-child) {
    margin-bottom: 24px;
  }

  .header {
    font-size: 12px;
    font-weight: 500;
    position: relative;
    margin-bottom: 16px;

    .status {
      position: absolute;
      top: 0;
      right: 16px;
      font-size: 10px;
      width: 90px;
      color: #fff;
      border-radius: 4px;

      span {
        background-color: #fb6514;
        border-radius: 4px;
        display: block;
        text-align: center;
        width: 100px;
      }

      &_enrolled {
        span {
          background-color: #12b76a;
        }
      }
    }
  }

  .general {
    display: flex;
    align-items: center;

    .avatar {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      background-color: var(--color-grey-2);
      margin-right: 60px;
    }

    .generalInfo {
      .name {
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 10px;
      }

      .role {
        font-size: 12px;
      }
    }
  }

  .contacts {
    .contact {
      display: flex;
      font-size: 12px;
      margin-bottom: 16px;

      .title {
        width: 120px;
        margin-right: 40px;
        font-weight: 500;
        color: var(--color-grey-4);
      }
    }
  }
}

.modalSpinner {
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.title {
  cursor: pointer;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  text-transform: uppercase;
  color: #667085;
  width: 15vw;
  border-bottom: 1px solid rgba(232, 236, 242, 1);
  text-align: center;
  &__wrapper {
    display: flex;
    margin-bottom: 20px;
  }
}

.filter {
  &__wrapper {
    display: flex;
    justify-content: space-between;
  }
}
