.comment {
  &__wrapper {
    margin: 10px 20px;
  }
  &__avatar {
    align-self: center;
    width: 32px;
    height: 32px;
    border-radius: 50%;
  }
  &__content {
    display: flex;
  }
  &__reacted {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
    &__left {
      display: flex;
      align-items: center;
    }
    &__rigth {
      display: flex;
      align-items: center;
    }
  }
  &__info {
    position: relative;
    font-family: 'Poppins', sans-serif;
    border-radius: 0px 8px 8px 8px;
    background-color: #f2f4f7;
    width: 95%;
    margin-left: 15px;
    margin-bottom: 10px;
    padding: 8px;
    font-style: normal;
    &__name {
      font-weight: 500;
      font-size: 14px;
      text-transform: capitalize;
      color: #1d2939;
    }
    &__text {
      margin-top: 10px;
      margin-bottom: 10px;
      font-weight: 400;
      font-size: 12px;
      color: #1d2939;
    }
    &__time {
      position: absolute;
      right: 10px;
      top: 10px;
      font-weight: 400;
      font-size: 10px;
      color: #98a2b3;
    }
  }
  &__reply {
    display: flex;
    align-items: center;
    font-family: 'Poppins', sans-serif;
    cursor: pointer;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #667085;
    img {
      margin-right: 5px;
    }
  }
}
