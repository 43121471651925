.user {
  &__item {
    display: flex;
    align-items: center;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #344054;
    margin-bottom: 10px;
    color: #344054;
    min-width: 160px;
    text-align: center;
    padding: 10px;
  }
  &__name {
    margin-left: 10px;
    margin-right: 10px;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    white-space: nowrap;
    color: #101828;
  }
  &__role {
    margin-right: 10px;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #667085;
  }
}
