.post {
  background: #f9fafb;
  box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06);
  border-radius: 8px;
  font-family: 'Poppins', sans-serif;
  padding: 16px;
  width: 65%;
  &__title {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #101828;
    margin-bottom: 10px;
  }
  &__subtitle {
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    color: #667085;
    margin-bottom: 10px;
  }
  &__textarea {
    &__wrapper {
      position: relative;
      max-width: 100%;
    }
    width: 100%;
    padding: 10px 20px 10px 30px;
    border: 1px solid rgb(234, 236, 240);
    margin-top: 10px;
    height: 145px;
    resize: none;
    height: 70px;
    border-radius: 4px;
    &__image {
      position: absolute;
      top: 22px;
      left: 10px;
    }
  }
  &__select {
    &__value {
      display: flex;
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      color: #000000;
      img {
        display: inline-flex;
        align-content: center;
        justify-content: center;
        width: 22px;
        height: 22px;
        margin-right: 5px;
        border-radius: 50%;
      }
      span {
        align-self: center;
      }
    }
    &__option {
      display: flex;
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      color: #000000;
      img {
        display: inline-flex;
        align-content: center;
        margin-right: 5px;
        width: 22px;
        height: 22px;
        border-radius: 50%;
      }
      span {
        align-self: center;
      }
    }

    &__placeholder {
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      color: #98a2b3;
      img {
        width: 14px;
        height: auto;
        margin-right: 5px;
      }
    }
  }
  &__error {
    font-size: 12px;
    color: red;
  }
  &__attach {
    img {
      width: 14px;
      height: auto;
    }
    span {
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      color: #667085;
    }
  }
  &__hashtags {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    border-bottom: 1px solid #eaecf0;

    &__subtitle {
      margin: 10px 0;
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      color: #667085;
    }
    button {
      cursor: pointer;
      border: 1px solid #eaecf0;
      background-color: #f9fafb;
      box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06);
      border-radius: 4px;
      padding: 2px 10px;
      margin-right: 10px;
      margin-bottom: 10px;
      display: flex;
      img {
        align-self: center;
        margin-right: 5px;
      }
      span {
        align-self: center;
      }
    }
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    &__left {
      display: flex;
      flex-direction: column;
      font-weight: 400;
      font-size: 12px;
      color: #344054;
      font-family: 'Poppins', sans-serif;
      .post__cash {
        cursor: pointer;
        margin: 10px 0;
        align-self: center;
        display: flex;
        span {
          padding: 0 10px;
          align-self: center;
        }
      }
      .post__user {
        cursor: pointer;
        display: flex;
        span {
          padding: 0 10px;
          align-self: center;
        }
      }
    }
    &__right {
      display: flex;
      width: 200px;
      justify-content: space-between;
      font-family: 'Poppins', sans-serif;

      .post__footer__cancel {
        align-self: center;
        cursor: pointer;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        align-items: center;
        text-align: center;
        color: #667085;
        border: none;
        background-color: transparent;
      }

      .post__footer__recognize {
        align-self: center;
        display: flex;
        background-color: #0066fb;
        border: 1px solid #0066fb;
        color: #ffffff;
        border-radius: 8px;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 8px 16px;
        cursor: pointer;
      }
    }
  }
}

.post__hashtags {
  button.post__hashtags__active {
    border-color: #0066fb;
    color: #0066fb;
  }
}
