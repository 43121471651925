.addon {
  &__wrapper {
    display: flex;
    min-width: 112px;
    min-height: 40px;
    align-items: center;
    border: 1px solid #4d94fc;
    border-radius: 8px;
    padding: 6px 20px;
    background-color: #f9fafb;
    color: #0066fb;
    cursor: pointer;

    &__disable {
      display: flex;
      align-items: center;
      min-width: 112px;
      min-height: 40px;
      border: 1px solid #d0d5dd;
      border-radius: 8px;
      padding: 6px 16px;
      background-color: #f9fafb;
      color: #98a2b3;
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      align-self: center;
    }

    span {
      font-family: 'Poppins', sans-serif;
      white-space: nowrap;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      align-self: center;
      margin-left: 5px;
    }
    img {
      width: 16px;
      height: 16px;
      align-self: center;
    }
  }
}
