.posts {
  margin: 20px 20px 20px 30px;
  background: #f9fafb;
  box-shadow: 0px 4px 8px -2px rgb(16 24 40 / 10%), 0px 2px 4px -2px rgb(16 24 40 / 6%);
  border-radius: 8px;
  &__headers {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #00388a;
    &__left {
      align-items: center;
      display: flex;
      span {
        padding: 0 5px;
      }
      img {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        align-self: center;
        margin-right: 10px;
      }
      p {
        align-self: center;
      }
    }
    &__right {
      align-items: center;
      display: flex;
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      color: #98a2b3;
      img {
        margin-left: 10px;
      }
    }
  }

  &__content {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    padding: 20px;
    a {
      color: #0047b0;
      font-weight: 600;
    }
  }

  &__hashtags {
    margin-left: 20px;
  }
  &__users {
    display: flex;
    align-items: center;
    margin-left: 20px;
    color: #667085;
    font-size: 12px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-style: normal;
    margin-bottom: 20px;
  }
  &__footer {
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;
    border-top: 1px solid rgba(234, 236, 240, 1);
    &__left {
      display: flex;
      align-items: center;
    }

    &__right {
      display: flex;
      align-items: center;
    }
    &__like {
      display: flex;
      margin-right: 25px;
      cursor: pointer;
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      color: #667085;
      img {
        margin-right: 10px;
      }
      span {
        align-self: center;
      }
    }
  }

  &__image {
    display: flex;
    justify-content: center;
    margin: 10px auto;
    width: 15vw;
  }
  &__cudos {
    width: 131px;
    height: 39px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    background-color: #0066fb;
    border-radius: 0px 12px 12px 0px;
    color: #ffffff;
    font-weight: 700;
    span {
      padding: 0 5px;
    }
  }

  &__hashtags {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    margin: 10px 20px 10px 20px;
    &__subtitle {
      margin: 10px 0;
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      color: #667085;
    }
    button {
      // cursor: pointer;
      border: 1px solid #eaecf0;
      background-color: #fff;
      border-radius: 4px;
      padding: 2px 10px;
      margin-right: 10px;
      margin-bottom: 10px;
      display: flex;
      img {
        align-self: center;
        margin-right: 5px;
      }
      span {
        align-self: center;
      }
    }
  }

  &__reacted {
    display: flex;
    justify-content: space-between;
    margin: 10px 20px 10px 0px;
  }
}

.comment {
  display: flex;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  color: #667085;
  img {
    margin-right: 10px;
  }
  span {
    margin-left: 5px;
    align-self: center;
  }
}
