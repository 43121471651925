.header {
  display: flex;
  justify-content: space-between;
  margin: 30px;
  &__title {
    display: flex;
    align-items: center;
    h1 {
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 32px;
      color: #101828;
      margin-right: 10px;
    }
    img {
      width: 20px;
      height: 20px;
      cursor: pointer;
    }
  }
  &__wallet {
    border: 1px solid #344054;
    border-radius: 6px;
    background-color: #fff;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    display: flex;
    align-items: center;
    align-self: center;
    height: 32px;
    padding: 0 12px;
    color: #344054;
    img {
      margin-right: 5px;
    }
  }
}

.main {
  border: 1px solid #eaecf0;
  border-radius: 16px;
  margin: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 30px;
  align-items: center;
  font-family: 'Poppins', sans-serif;
  &__info {
    display: flex;
    align-items: center;
    &__total {
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      color: #475467;
    }
    &__balance {
      font-style: normal;
      font-weight: 500;
      font-size: 36px;
      color: #101828;
      padding: 0 10px;
    }
    &__bitcoin {
      width: 32px;
      height: 32px;
      margin: 0 10px;
    }
    &__exchange {
      width: 24px;
      height: 24px;
      margin: 0 10px;
    }
    &__value {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      color: #344054;
    }
  }
  &__withdraw {
    border: 1px solid #d0d5dd;
    border-radius: 8px;
    padding: 10px 30px;
    display: flex;
    align-self: center;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #344054;
    img {
      align-self: center;
      padding-right: 10px;
    }
  }
}
.table {
  background: #f9fafb;
  border-radius: 16px;
  min-height: 50vh;
  margin: 30px;
  padding: 20px;
  font-family: 'Poppins', sans-serif;
  justify-content: center;
  display: flex;
  flex-direction: column;
  position: relative;
  h1 {
    position: absolute;
    top: 16px;
    left: 16px;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    color: #1d2939;
  }
  &__empty {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 45%;
    margin: auto;
    p {
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      color: #101828;
    }
    button {
      background-color: #0066fb;
      padding: 6px 0px;
      border: 1px solid #0066fb;
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 20px;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      color: #fff;
      img {
        width: 22px;
        height: 18px;
        margin-right: 10px;
      }
    }
  }
}
