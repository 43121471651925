.inputWrapper {
  position: relative;

  .label {
    font-size: 12px;
    color: var(--color-grey-5);

    &.invalidLabel {
      color: var(--color-red-5);
    }
  }
}

.input {
  font-size: 12px;
  line-height: 18px;
  padding: 8px;
  border: 1px solid var(--color-grey-2);
  border-radius: 4px;
  width: 100%;
  outline: none;

  &:disabled {
    background-color: var(--color-grey-2);
    color: var(--color-grey-4);

    &:hover {
      border: 1px solid var(--color-grey-2);
    }
  }

  &.invalidInput {
    border: 1px solid var(--color-red-5);

    &:hover {
      border: 1px solid var(--color-red-5);
    }

    &:focus {
      border: 1px solid var(--color-red-5);
    }
  }

  &::placeholder {
    color: var(--color-grey-4);
  }

  &:hover {
    border: 1px solid var(--color-grey-3);
  }

  &:focus {
    border: 1px solid var(--color-primary-5);
  }
}

.eyeIcon {
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.error {
  margin-top: 5px;
  font-size: 10px;
  display: flex;
  color: var(--color-red-5);

  svg {
    margin-right: 5px;
  }
}
