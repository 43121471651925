.hashtags {
  &__wrapper {
    display: flex;
    margin: 10px 25px;
    width: 63%;
    align-items: center;
    justify-content: space-between;
  }

  &__item {
    &__wrapper {
      display: flex;
      flex-direction: row;
    }
    cursor: pointer;
    padding: 2px 10px;
    margin-right: 15px;
    background: #ffffff;
    border: 1px solid #eaecf0;
    border-radius: 4px;
  }
  img {
    margin-right: 5px;
  }
  &__icon {
    margin-left: 10px;
  }
  &__clear {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    display: flex;
    cursor: pointer;
    align-items: center;
    color: #475467;
    margin-right: 20px;
    &__value {
      margin-left: 10px;
    }
  }
}
