.wrapper {
  display: flex;
  flex-direction: column;
  width: 70%;
  max-width: 400px;

  .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-bottom: 16px;

    h2 {
      font-weight: 700;
      margin-bottom: 10px;
    }

    img {
      margin-bottom: 24px;
    }

    p {
      font-size: 16px;
      color: var(--color-grey-5);
    }
  }

  .form {
    display: flex;
    flex-direction: column;

    .formItem {
      margin-bottom: 24px;

      .passwordHint {
        font-size: 12px;
        color: var(--color-grey-5);
      }
    }

    button {
      margin-bottom: 24px;
    }
  }

  .haveAccount {
    font-size: 14px;
    color: var(--color-grey-6);
    text-align: center;
  }

  h2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 16px;

    img {
      margin-bottom: 24px;
    }
  }

  button {
    margin-bottom: 24px;
  }

  .googleButton {
    button {
      width: 100%;

      img {
        margin-right: 10px;
      }
    }
  }
}
