.filter {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #344054;
  margin-left: 55px;
  margin-bottom: 20px;
  cursor: pointer;

  &__input {
    border: 1px solid #eaecf0;
    border-radius: 4px;
    width: 100%;
    padding: 5px 20px 5px 30px;
    margin: 0 0px 0 10px;
    &__icon {
      position: absolute;
      left: 20px;
      top: 10px;
      z-index: 100;
    }
    &:focus-visible {
      outline: none;
    }
    &__wrapper {
      position: relative;
      width: 90%;
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
    }
  }
  &__icons {
    width: 24px;
    height: 24px;
  }
  position: relative;
  &__button {
    display: flex;
  }
  &__select {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: space-between;
    min-width: 131px;
    height: 32px;
    padding: 10px;
    &__open {
      cursor: pointer;
      position: relative;
      padding: 0px;
      display: flex;
      min-width: 106px;
      height: 32px;
      justify-content: space-around;
      color: white;
      background-color: #267dfc;
      align-items: center;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }
  }

  &__arrow {
    &__open {
      width: 24px;
      height: 32px;
      margin-left: 1px;
      background-color: #267dfc;
      display: flex;
      align-items: center;
      justify-content: center;
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
      img {
        width: 7px;
        height: 5px;
      }
    }
    &__close {
      margin-right: 10px;
    }
  }
}

.modal {
  &__user {
    &__filter {
      flex-direction: column;
      z-index: 111111;
      position: absolute;
      background-color: #fff;
      display: flex;
      filter: drop-shadow(0px 8px 32px rgba(91, 112, 133, 0.35));
      border-radius: 8px;
      padding: 10px 0px;
      min-width: 220px;
    }
  }
}
