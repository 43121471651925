.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  overflow: auto;
  cursor: auto;
  &__container {
    height: auto;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 20px;
    background-color: #f9fafb;
    border-radius: 16px;

    &_aside {
      height: 100%;
      position: absolute;
      left: initial;
      right: 0;
      top: 0;
      width: 40%;
      border-radius: 0;
      background-color: #fff;
      transform: none;
    }
  }
  &__button {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
  }
}
