.aside {
  width: 245px;
  height: 100vh;
  background-color: var(--color-grey-05);
  color: var(--color-grey-8);
  font-weight: 500;
  font-size: 12px;
  padding: 16px 20px;
  position: relative;
  transition: .3s;

  &_collapsed{
    width: 80px;
  }

  .collapseArrow {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    position: absolute;
    right: 0;
    bottom: 0;
    background-color: var(--color-grey-2);
    cursor: pointer;

    &_collapsed{
      transform: rotate(180deg);
    }
  }

  .logoWrapper {
    svg {
      max-height: 32px;
      width: 100%;
    }
  }
}

.navLinks {
  margin-top: 130px;
}

.navlink {
  display: flex;
  align-items: center;
  text-transform: uppercase;
  text-decoration: none;
  color: var(--color-grey-8);
  margin-bottom: 24px;

  .iconWrapper {
    min-width: 32px;
    width: 32px;
    height: 32px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
    transition: 0.3s;
    background-color: transparent;

    &_collapsed {
      margin-right: 0;
    }

    svg {
      path {
        transition: 0.3s;
      }
    }
  }

  &_active {
    font-weight: 600;

    .iconWrapper {
      background-color: var(--color-primary-5);

      svg {
        path {
          stroke: #fff;
        }
      }
    }
  }

  &_collapsed {
    justify-content: center;
  }
}

.profileLinkWrapper {
  position: absolute;
  bottom: 80px;

  .profileLink {
    max-width: 150px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    text-decoration: none;
    font-size: 12px;
    color: var(--color-grey-8);

    &.profileLink_active {
      font-weight: 600;
      .userImage {
        background-color: var(--color-primary-5);
        border: 2px solid var(--color-primary-5);
      }
    }

    .userImage {
      border: 2px solid #f9fafb;
      min-width: 36px;
      width: 36px;
      height: 36px;
      border-radius: 50%;
      background-color: var(--color-grey-4);
      margin-right: 12px;
      transition: 0.3s;
    }
  }
}
