.page {
  padding: 30px;
  height: 100vh;

  h2 {
    font-size: 32px;
    font-weight: 500;
    margin-bottom: 24px;
  }

  .block {
    display: flex;
    justify-content: space-between;
    padding: 16px;
    border: 1px solid var(--color-grey-2);
    border-radius: 16px;
    margin-bottom: 16px;

    .block_section {
      .card {
        display: flex;
        align-items: center;

        .cardImage {
          width: 46px;
          height: 32px;
          border: 1px solid var(--color-grey-2);
          border-radius: 6px;
          text-align: center;
          margin-right: 5px;

          svg {
            max-width: 46px;
            max-height: 30px;
          }
        }

        .cardDetails {
          .cardNumber {
            font-weight: 500;
            font-size: 16px;
            color: var(--color-grey-7);
          }

          .expireDate {
            font-weight: 400;
            font-size: 12px;
            color: var(--color-grey-4);
          }
        }
      }

      .updatePaymentButton {
        margin-top: 20px;

        button {
          color: var(--color-primary-5);
          border: 1px solid var(--color-primary-5);
        }
      }
    }

    .subtitle {
      font-size: 14px;
      display: block;
      color: var(--color-grey-6);
      margin-bottom: 16px;
    }

    .planInfo {
      display: flex;
      align-items: center;
      gap: 50px;
      max-width: 350px;
      margin-bottom: 20px;

      .planName {
        min-width: 140px;
        font-size: 24px;
        position: relative;

        .newFeatures {
          display: block;
          position: absolute;
          font-size: 10px;
          font-weight: 600;
          color: #fff;
          text-transform: uppercase;
          padding: 0 3px;
          border-radius: 4px;
          background-color: var(--color-primary-4);
          top: -14px;
          right: -20px;
        }
      }

      .planPrice {
        display: flex;
        align-items: center;
        font-size: 24px;
        font-weight: 700;
        color: var(--color-primary-5);

        .currencySign {
          font-size: 16px;
          font-weight: 400;
          color: #000;
          align-self: flex-start;
        }

        span {
          margin-left: 10px;
          font-size: 12px;
          font-weight: 400;
          color: var(--color-grey-4);
        }
      }
    }

    .buttons {
      display: flex;
      justify-content: space-between;

      button {
        &:first-child {
          display: flex;
          align-items: center;

          svg {
            margin-left: 10px;
          }
        }

        &:last-child {
          font-size: 12px;
          font-weight: 400;
          text-decoration: underline;
          color: var(--color-grey-4);
        }
      }
    }

    .flex {
      display: flex;
      align-items: center;

      .totalPrice {
        margin-left: 16px;
        font-weight: 500;
        font-size: 28px;
      }

      .total {
        .totalTime {
        }
        span {
          font-size: 14px;
          color: var(--color-grey-4);
        }
      }
    }
  }

  .tableWrapper {
    width: 100%;
    max-height: calc(100% - 136px);
    overflow: auto;
    padding: 16px;
    background-color: var(--color-grey-05);
    border-radius: 16px;

    h3 {
      font-size: 24px;
      font-weight: 500;
      margin-bottom: 16px;
    }
  }
}

.modalButtons {
  display: flex;
  margin: 0 auto;
  justify-content: center;

  button {
    width: 100%;

    svg {
      margin-right: 8px;
    }

    &:last-child {
      margin-left: 16px;
    }
  }
}

.cancelModalHeaderIcon {
  display: flex;
  justify-content: center;

  .iconWrapper {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: var(--color-red-2);
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05), 0 0 0 4px rgba(255, 143, 162, 0.2);
    margin-bottom: 16px;
  }
  svg {
    width: 20px;
    height: 20px;
  }
}

.cancelModalHeader {
  font-size: 18px;
  font-weight: 400;
  text-align: center;
  margin-bottom: 8px;
}

.cancelModalDescription {
  text-align: center;
  max-width: 440px;
  margin-bottom: 32px;
  color: var(--color-grey-5);
}

.updateModalHeader {
  margin-bottom: 16px;
}

.paymentMethodForm {
  .cardDetailsBottom {
    margin: 16px 0 24px 0;
    display: flex;
    justify-content: space-between;
  }

  button {
    width: 100%;
  }
}
