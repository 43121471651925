.anniversaries {
  &__wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px 20px 15px;
  }
  &__content {
    display: flex;
    align-items: center;
  }
  &__info {
    display: flex;
    flex-direction: column;
  }
  &__detail {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    text-decoration-line: underline;
    color: #00388a;
  }
  &__time {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #667085;
  }
}
