.checkbox {
  display: flex;
  align-items: center;
  font-size: 14px;
  min-height: 16px;
  cursor: pointer;
  padding-left: 26px;
  position: relative;

  input {
    width: 16px;
    height: 16px;
    cursor: pointer;
    appearance: none;
    position: absolute;
    top: 0;
    left: 0;

    &:checked {
      + .checkBtn {
        background-color: var(--color-primary-5);
        border: none;
        background-image: url('../../assets/icons/checkmark.svg');
        background-position: center;
        background-repeat: no-repeat;
      }
      &:disabled {
        + .checkBtn {
          background-color: var(--color-grey-4);
          background-image: url('../../assets/icons/checkmark.svg');
          background-position: center;
          background-repeat: no-repeat;
        }
      }
    }

    &:disabled {
      + .checkBtn {
        background-color: var(--color-grey-3);
        border: none;
      }
    }
  }

  .checkBtn {
    position: absolute;
    width: 16px;
    height: 16px;
    border-radius: 3px;
    border: 2px solid var(--color-grey-2);
    margin-left: -26px;
  }
}

.error {
  margin-top: 5px;
  font-size: 10px;
  display: flex;
  color: var(--color-red-5);

  svg {
    margin-right: 5px;
  }
}

.switch {
  padding-left: 44px;

  input {
    width: 36px;
    height: 20px;

    &:checked {
      + .checkBtn {
        background-color: var(--color-primary-5);
        background-image: none;
        border: none;

        &:before {
          left: 18px;
          //right: 2px;
        }
      }
    }
  }

  .checkBtn {
    position: absolute;
    width: 36px;
    height: 20px;
    background-color: var(--color-grey-2);
    border: none;
    border-radius: 12px;
    margin-left: -44px;
    transition: .3s;

    &:before {
      content: '';
      position: absolute;
      top: 2px;
      left: 2px;
      width: 16px;
      height: 16px;
      background-color: #fff;
      border-radius: 50%;
      transition: .3s;
    }
  }
}
