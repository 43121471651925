.reacted {
  &__wrapper {
    display: flex;
    align-items: center;
  }
  &__icon {
    border-radius: 50%;
    width: 24px;
    height: 24px;
    background-color: grey;
  }
  &__content {
    font-family: 'Poppins', sans-serif;
    align-self: center;
    margin-left: 5px;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #667085;
  }
}
