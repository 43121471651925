.wrapper {
  display: flex;
  margin-top: 20px;
  margin-left: 14px;

  .option {
    position: relative;
    width: 150px;
    height: 4px;
    background-color: var(--color-primary-025);
    transition: .3s;

    &:last-child {
      width: 1px;
    }

    &_active {
      background-color: var(--color-primary-5);
    }

    .optionLabel {
      position: absolute;
      bottom: 10px;
      left: -10px;
      font-size: 14px;
      color: var(--color-primary-8);
    }

    .optionButton {
      position: absolute;
      top: -4px;
      left: -1px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background-color: var(--color-primary-5);
      cursor: pointer;
      transition: .3s;

      &_active {
        width: 18px;
        height: 18px;
        top: -7px;
      }
    }
  }
}