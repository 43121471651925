.phraseForm {
  p {
    display: flex;
    align-items: center;
    margin: 16px 0;
    font-size: 12px;
    color: var(--color-grey-5);

    svg {
      margin-right: 10px;
    }
  }

  .form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .formItem {
      margin-bottom: 16px;
      max-width: 122px;
    }

    .buttons {
      display: flex;
      width: 100%;
      justify-content: space-between;

      button {
        width: calc(50% - 8px);
      }
    }
  }
}
