.like {
  &__wrapper {
    display: flex;
    align-items: center;
    font-family: 'Poppins', sans-serif;
    cursor: pointer;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: #0066fb;
    img {
      width: 14px;
      height: 14px;
      margin-right: 5px;
    }
    span {
      margin-right: 5px;
    }
  }
}
