.filter {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #344054;
  margin-left: 55px;
  margin-bottom: 20px;
  cursor: pointer;
  position: relative;
  &__button {
    display: flex;
  }
  &__cancel {
    cursor: pointer;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: #344054;
    border-radius: 8px;
    padding: 6px 12px;
    border: 1px solid rgba(234, 236, 240, 1);
    margin-right: 10px;
  }
  &__apply {
    cursor: pointer;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: rgba(255, 255, 255, 1);
    border-radius: 8px;
    padding: 6px 16px;
    background-color: rgba(0, 102, 251, 1);
    border: 1px solid rgba(234, 236, 240, 1);
  }
  &__select {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: space-between;
    width: 131px;
    height: 32px;
    padding: 10px;
    &__open {
      cursor: pointer;
      position: relative;
      padding: 0px;
      display: flex;
      width: 106px;
      height: 32px;
      justify-content: space-around;
      color: white;
      background-color: #267dfc;
      align-items: center;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }
  }

  &__arrow {
    &__open {
      width: 24px;
      height: 32px;
      margin-left: 1px;
      background-color: #267dfc;
      display: flex;
      align-items: center;
      justify-content: center;
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
      img {
        width: 7px;
        height: 5px;
      }
    }
    &__close {
      margin-right: 10px;
    }
  }

  .modal {
    &__filter {
      z-index: 111111;
      position: absolute;
      background-color: #fff;
      display: flex;
      filter: drop-shadow(0px 8px 32px rgba(91, 112, 133, 0.35));
      border-radius: 8px;
      padding: 10px 20px;
      .filter__calendar {
        position: relative;
        width: 70vh;
        .datapicker-input {
          border-radius: 8px;
          border: 1px solid rgba(234, 236, 240, 1);
          padding: 2px 10px;
          cursor: pointer;
          font-family: 'Poppins', sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          color: rgba(52, 64, 84, 1);
          background-color: rgba(255, 255, 255, 1);
        }
        .react-datepicker {
          box-shadow: none;
        }
        .react-datepicker-wrapper {
          display: flex;
          padding: 0;
          border: 0;
          position: absolute;
          bottom: 0px;
          z-index: 22222222;
        }
        .react-datepicker-popper {
          position: relative !important;
          transform: translate(0) !important;
        }
      }
    }
    &__item {
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      margin-bottom: 10px;
      color: #344054;
      min-width: 160px;
      text-align: center;
      padding: 10px;
      &.active {
        border-radius: 8px;
        background-color: #bfd9fe;
      }
    }
  }
}
