.wrapper {
  background-color: var(--color-grey-05);
  border-radius: 16px;
  padding: 16px;

  .addCardMessage {
    margin: 120px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 345px;

    p {
      font-size: 18px;
      font-weight: 500;
      text-align: center;
      margin-bottom: 32px;
    }

    button {
      width: 100%;
    }
  }
}

.titleWrapper {
  margin-bottom: 24px;

  h4 {
    font-weight: 500;
    font-size: 14px;
  }
  p {
    font-size: 14px;
    color: var(--color-grey-5);
  }
}

.formWrapper {
  max-width: 75%;
}

.inputWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;

  .fieldsWrapper {
    display: flex;
  }

  &:first-child {
    .fieldWrapper {
      min-width: 242px;

      &:last-child {
        margin-left: 16px;
      }
    }
  }

  .fieldWrapper {
    min-width: 500px;
  }

  h4 {
    font-size: 14px;
    font-weight: 500;
    color: var(--color-grey-8);
  }
}
