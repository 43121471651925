.comment {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0px 20px 10px;
  label {
    position: relative;
    width: 80%;
    margin-left: 10px;
    margin-right: 10px;
  }
  &__avatar {
    border-radius: 50%;
    width: 32px;
    height: 32px;
    background-color: grey;
    align-self: center;
  }

  &__smile {
    position: absolute;
    top: 16px;
    right: 10px;
  }
  input[type='text'] {
    width: 100%;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    padding: 6px;
    margin: 8px 0;
    box-sizing: border-box;
    border: 1px solid #eaecf0;
    border-radius: 4px;
    padding-right: 30px;
  }

  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 10%;
    cursor: pointer;
    background-color: #0066fb;
    border: 1px solid #0066fb;
    border-radius: 6px;
    padding: 5px 14px;
    &:hover {
      opacity: 0.7;
    }
    span {
      margin-left: 5px;
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      align-items: center;
      text-align: center;
      color: #ffffff;
    }
  }
}
