@import 'variables';
@import 'sortable';

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

#root {
  overflow: hidden;
  height: 100vh;
}

.customTooltip {
  color: #000 !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  background-color: #fff !important;
  box-shadow: 0 20px 24px -4px rgba(16, 24, 40, 0.08), 0 8px 8px -4px rgba(16, 24, 40, 0.03);
  border-top: 3px solid var(--color-primary-5) !important;

  &:after {
    background-color: var(--color-primary-5) !important;
  }
}

.limitedWidthTooltip {
  max-width: 250px;
}
