.page {
  padding: 30px;
  height: 100vh;

  h2 {
    font-size: 32px;
    font-weight: 500;
    margin-bottom: 24px;
  }
}

.controls {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;

  .filters {
    display: flex;
    align-items: center;

    .searchInput {
      min-width: 250px;
      margin-right: 32px;
    }
  }

  .filterWrapper {
    position: relative;
    button {
      padding: 7px;

      border-radius: 4px;
      svg {
        &:first-child {
          margin-right: 5px;
        }
        &:last-child {
          margin-left: 5px;
          border-left: 1px solid #fff;
        }
      }
    }

    &_active {
      button {
        background-color: var(--color-primary-5);
        color: var(--color-primary-025);

        svg {
          path {
            fill: var(--color-primary-025);
          }
        }
      }
    }

    .filter {
      position: absolute;
      background-color: #fff;
      border-radius: 16px;
      padding: 24px;
      margin-top: 16px;
      left: -60px;
      box-shadow: 0 12px 40px rgba(50, 61, 73, 0.4);
      min-width: 560px;

      .columnsWrapper {
        display: flex;
        justify-content: space-between;

        .column {
          font-size: 12px;
          width: calc(50% - 12px);

          .filterTitle {
            padding: 12px;
            font-weight: 500;
            color: var(--color-grey-5);
          }

          .filterItems {
            display: flex;
            flex-direction: column;
            max-height: 200px;
            overflow: auto;

            .filterItem {
              padding: 12px;
              background-color: var(--color-grey-05);
              border: 1px solid var(--color-grey-2);
              border-radius: 4px;
              cursor: pointer;

              &:not(:last-child) {
                margin-bottom: 4px;
              }

              &_active {
                background-color: var(--color-primary-4);
                color: var(--color-primary-025);
                border: 1px solid var(--color-primary-4);
              }
            }
          }
        }
      }

      .clearButton {
        position: absolute;
        right: 24px;

        button {
          background-color: transparent;
          color: var(--color-grey-5);
        }
      }
    }
  }

  .buttons {
    display: flex;
    align-items: center;

    button {
      svg {
        max-width: 20px;
        max-height: 20px;
        margin-right: 8px;
      }
      &:not(:first-child) {
        margin-left: 32px;
      }
    }
  }
}

.content {
  width: 100%;
  max-height: calc(100% - 136px);
  padding: 16px;
  overflow: auto;
  border-radius: 16px;
  background-color: var(--color-grey-05);
}

.modalHeaderIcon {
  .iconWrapper {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: var(--color-primary-1);
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05), 0 0 0 6px rgba(191, 217, 254, 0.2);
    margin-bottom: 16px;
  }
}

.modalHeader {
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 8px;
}

.modalDescription {
  max-width: 440px;
  font-size: 14px;
  color: var(--color-grey-5);
  margin-bottom: 16px;
}

.multiInput {
  margin-bottom: 32px;
}

.csvUploader {
  margin-bottom: 32px;
}

.fireModalHeaderIcon {
  display: flex;
  justify-content: center;

  .iconWrapper {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: var(--color-red-2);
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05), 0 0 0 4px rgba(255, 143, 162, 0.2);
    margin-bottom: 16px;
  }
  svg {
    width: 20px;
    height: 20px;
  }
}

.fireModalHeader {
  font-size: 18px;
  font-weight: 400;
  text-align: center;
  margin-bottom: 8px;
}

.fireModalDescription {
  text-align: center;
  max-width: 440px;
  margin-bottom: 32px;
  color: var(--color-grey-5);
}

.fireModalInput {
  margin-bottom: 32px;
}

.modalButtons {
  display: flex;
  margin: 0 auto;
  justify-content: center;

  button {
    width: 100%;

    svg {
      margin-right: 8px;
    }

    &:last-child {
      margin-left: 16px;
    }
  }
}

.infoBlock {
  padding: 16px;
  width: 100%;
  border: 1px solid var(--color-grey-2);

  &:not(:last-child) {
    margin-bottom: 24px;
  }

  .header {
    font-size: 12px;
    font-weight: 500;
    position: relative;
    margin-bottom: 16px;

    .status {
      position: absolute;
      top: 0;
      right: 16px;
      font-size: 10px;
      width: 90px;
      color: #fff;
      border-radius: 4px;

      span {
        background-color: #fb6514;
        border-radius: 4px;
        display: block;
        text-align: center;
        width: 100px;
      }

      &_enrolled {
        span {
          background-color: #12b76a;
        }
      }
      &_fired {
        span {
          background-color: var(--color-red-5);
        }
      }
    }
  }

  .general {
    display: flex;
    align-items: center;

    .avatar {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      background-color: var(--color-grey-2);
      margin-right: 60px;
    }

    .generalInfo {
      .name {
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 10px;
      }

      .role {
        font-size: 12px;
      }
    }
  }

  .contacts {
    .contact {
      display: flex;
      font-size: 12px;
      margin-bottom: 16px;

      .title {
        width: 120px;
        margin-right: 40px;
        font-weight: 500;
        color: var(--color-grey-4);
      }
    }
  }
}

.modalSpinner {
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
}
