.page {
  padding: 30px;
  height: 100vh;

  h2 {
    font-size: 32px;
    font-weight: 500;
    margin-bottom: 24px;
    display: flex;
    align-items: center;

    svg {
      width: 22px;
      height: 22px;
      margin-right: 14px;
      transform: rotate(180deg);
      cursor: pointer;

      path {
        stroke: var(--color-grey-4);
      }
    }
  }
}

.content {
  padding: 16px;
  background-color: var(--color-grey-05);
  border-radius: 16px;

  .group {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    color: var(--color-primary-5);
    padding: 12px 24px;
    border-bottom: 1px solid var(--color-grey-2);
    background-color: var(--color-grey-1);

    svg {
      margin-right: 10px;
    }
  }

  .purchasedPlan {
    font-size: 14px;
    font-weight: 500;
    color: var(--color-primary-5);
  }

  .buttonWrapper {
    display: flex;
    justify-content: center;

    &_top {
      margin-top: 20px;
    }

    button {
      padding: 10px 50px;

      svg {
        margin-left: 6px;
      }
    }
  }

  .row {
    display: flex;
    align-items: center;
    width: 100%;
    font-size: 14px;
    border-bottom: 1px solid var(--color-grey-2);

    &.firstRow {
      align-items: flex-end;
    }

    .featureHeader {
      font-size: 24px;
      font-weight: 700;
      width: 50%;
      padding-bottom: 35px;
    }

    .presenceHeader {
      font-size: 24px;
      font-weight: 700;
      width: 50%;
      text-align: center;
      padding-bottom: 35px;

      .periodTabs {
        display: flex;
        width: fit-content;
        margin: 16px auto 0 auto;
        border: 1px solid var(--color-primary-4);
        border-radius: 4px;

        .tab {
          font-size: 12px;
          font-weight: 400;
          padding: 5px 16px;
          border-radius: 4px;
          color: var(--color-grey-6);
          cursor: pointer;
          transition: .3s;

          &_active {
            background-color: var(--color-primary-025);
            color: var(--color-primary-5);
          }

          .discount {
            color: orange;
          }
        }
      }

      .price {
        margin-top: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 24px;
        font-weight: 700;
        color: var(--color-primary-5);
      }

      .currencySign {
        font-size: 16px;
        font-weight: 400;
        color: #000;
        align-self: flex-start;
      }

      span {
        margin-left: 10px;
        font-size: 12px;
        font-weight: 400;
        color: var(--color-grey-4);
      }
    }

    .feature {
      width: 50%;
      padding: 16px 0 16px 64px;
    }

    .presence {
      width: 50%;
      text-align: center;
      padding: 16px 0;

      .svgWrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        background-color: var(--color-primary-1);
        box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05), 0 0 0 4px rgba(191, 217, 254, 0.4);

        svg {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
}