.page {
  padding: 30px 30px 0 30px;

  h2 {
    font-size: 32px;
    font-weight: 500;
    margin-bottom: 24px;
  }
}

.navTabs {
  display: flex;
  align-content: center;
  margin-bottom: 24px;

  .navlink {
    text-align: center;
    text-decoration: none;
    color: var(--color-grey-5);
    min-width: 290px;
    font-size: 12px;
    font-weight: 500;
    padding: 10px;
    text-transform: uppercase;
    border-bottom: 1px solid var(--color-grey-2);

    &.navlink_active {
      color: var(--color-primary-5);
      border-bottom: 3px solid var(--color-primary-5);
    }
  }
}
