.analytics {
  &__content {
    display: flex;
    flex-direction: row;
  }
  &__title {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    color: #101828;
    margin-left: 55px;
    margin: 20px 20px 20px 55px;
  }
  &__kudos {
    width: 48%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: 'Poppins', sans-serif;
    margin-left: 55px;
    margin-right: 70px;
  }
  &__addon {
    width: 48%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: 'Poppins', sans-serif;
    margin-left: -10px;
    margin-right: 30px;
  }
  &__chart {
    display: flex;
    margin: 20px 20px 10px 0;
  }
  &__filter {
    display: flex;
    flex-direction: row;
  }
}
