.wrapper {
  display: flex;
  justify-content: space-between;
  margin: 0 auto 32px auto;
  width: 80%;
  color: var(--color-primary-4);
  font-size: 12px;
  position: relative;

  &:after {
    position: absolute;
    content: '';
    width: 100%;
    height: 1px;
    background-color: var(--color-primary-4);
    margin: auto;
    top: 0;
    bottom: 0;
    z-index: 1;
  }

  .stepWrapper {
    display: flex;

    .step {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;
      background-color: #fff;
      border-radius: 50%;
      border: 1px solid var(--color-primary-4);
      box-shadow: 0px -2px 8px rgba(77, 114, 148, 0.1), 0px 6px 10px rgba(77, 114, 148, 0.2);
      z-index: 2;

      &.active {
        background-color: var(--color-primary-4);
        color: #fff;
      }
    }
  }
}
