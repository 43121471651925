.comment {
  &__wrapper {
    display: flex;
    align-items: center;
  }
  img {
    width: 15px;
    height: 16px;
    align-self: center;
  }
  &__content {
    margin-left: 5px;
    font-family: 'Poppins', sans-serif;
    cursor: pointer;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    align-self: center;
    color: #667085;
  }
}
