.filter {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #344054;
  margin-left: 55px;
  margin-bottom: 20px;
  cursor: pointer;

  &__icons {
    width: 12px;
    height: 12px;
    margin-left: 10px;
    margin-right: 10px;
  }
  position: relative;
  &__button {
    display: flex;
  }
  &__select {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: space-between;
    min-width: 131px;
    height: 32px;
    padding: 10px;
    span {
      margin-right: 10px;
    }
    svg {
      margin-right: 10px;
      margin-left: 10px;
    }
    path {
      fill: #344054;
    }
    &__open {
      path {
        fill: #fff;
      }
      cursor: pointer;
      position: relative;
      padding: 0px;
      display: flex;
      min-width: 106px;
      height: 32px;
      justify-content: space-around;
      color: white;
      background-color: #267dfc;
      align-items: center;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }
  }

  &__arrow {
    &__open {
      width: 24px;
      height: 32px;
      margin-left: 1px;
      background-color: #267dfc;
      display: flex;
      align-items: center;
      justify-content: center;
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
      img {
        width: 7px;
        height: 5px;
      }
    }
    &__close {
      margin-right: 10px;
    }
  }
}

.icon {
  width: 12px;
  height: 12px;
}
