:root {
  --color-grey-05: #f9fafb;
  --color-grey-1: #f2f4f7;
  --color-grey-2: #eaecf0;
  --color-grey-3: #d0d5dd;
  --color-grey-4: #98a2b3;
  --color-grey-5: #667085;
  --color-grey-6: #475467;
  --color-grey-7: #344054;
  --color-grey-8: #1d2939;
  --color-primary-025: #e6f0ff;
  --color-primary-05: #bfd9fe;
  --color-primary-1: #99c2fd;
  --color-primary-2: #73abfd;
  --color-primary-4: #267dfc;
  --color-primary-5: #0066fb;
  --color-primary-6: #0057d5;
  --color-primary-7: #0047B0;
  --color-primary-8: #00388a;
  --color-blue-05: #eff8ff;
  --color-red-1: #FEE4E2;
  --color-red-2: #FECDCA;
  --color-red-5: #f04438;
  --color-red-7: #B42318;
}
