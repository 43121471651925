.radio {
  input {
    margin-right: 8px;

  }
}

.error {
  margin-top: 5px;
  font-size: 10px;
  display: flex;
  color: var(--color-red-5);

  svg {
    margin-right: 5px;
  }
}