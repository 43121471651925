.list {
  list-style: none;

  .title {
    color: var(--color-grey-5);
    font-weight: 500;
  }

  li {
    display: grid;
    align-items: center;
    grid-template-columns:
      30px minmax(200px, 2fr) minmax(200px, 2fr) minmax(200px, 2fr)
      minmax(100px, 2fr) minmax(200px, 2fr) 30px;
    font-size: 12px;
    font-weight: 600;
    color: var(--color-grey-7);
    padding: 8px;
    border-bottom: 2px solid var(--color-grey-2);
    border-radius: 4px;

    &:last-child {
      border: none;
    }
  }
}

.row {
  &.rowSelected {
    background-color: var(--color-primary-025);
  }
}

.col {
  display: flex;
  align-items: center;

  &.img {
    cursor: pointer;
  }
  .userImage {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    margin-right: 8px;
    background-color: var(--color-grey-2);
  }

  .name {
    display: flex;
    flex-direction: column;

    span {
      font-size: 10px;
      font-weight: 500;
      color: var(--color-primary-7);
    }
  }

  &.walletNumberWrapper {
    svg {
      &:last-child {
        cursor: pointer;
      }
    }
  }

  .walletNumber {
    padding: 0 5px;
  }

  &.status {
    display: flex;
    justify-content: center;
    color: #fff;
    border-radius: 4px;

    span {
      background-color: #fb6514;
      border-radius: 4px;
      display: block;
      text-align: center;
      width: 100px;
    }

    &_title {
      display: flex;
      justify-content: center;
    }

    &_enrolled {
      span {
        background-color: #12b76a;
      }
    }
  }

  &.kebabMenu {
    position: relative;

    .userMenu {
      position: absolute;
      z-index: 1;
      display: none;
      min-width: 186px;
      top: 20px;
      right: 20px;
      padding: 10px;
      color: var(--color-grey-7);
      font-size: 14px;
      font-weight: 400;
      background-color: #fff;
      border: 1px solid var(--color-grey-1);
      border-radius: 8px;
      box-shadow: 0 20px 24px -4px rgba(16, 24, 40, 0.08), 0 8px 8px -4px rgba(16, 24, 40, 0.03);

      &_open {
        display: flex;
        flex-direction: column;
      }

      &_item {
        display: flex;
        align-items: center;
        cursor: pointer;

        &:not(:last-child) {
          margin-bottom: 10px;
        }

        svg {
          margin-right: 5px;
          width: 16px;
          path {
            stroke: var(--color-grey-7);
          }
        }

        &__fire {
          color: var(--color-red-7);
          svg {
            path {
              stroke: transparent;
              fill: var(--color-red-7);
            }
          }
        }
      }
    }
    svg {
      cursor: pointer;
    }
  }
  &__status {
    background-color: #fb6514;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    color: #ffffff;
    border-radius: 4px;
    padding: 2px 14px;
  }
}

.amount {
  &__bitcoin {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #98a2b3;
  }
}

.arrow {
  margin-left: 5px;
}
