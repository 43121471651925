.birthdays {
  &__wrapper {
    width: 25vw;
    margin-top: 40px;
    border-radius: 8px;
    background-color: #f9fafb;
  }
  &__title {
    display: flex;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #000000;
    border-bottom: 1px solid #eaecf0;
    padding: 10px 20px;
    img {
      width: 21px;
      height: 21px;
      margin-right: 10px;
    }
  }
}
