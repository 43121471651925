.balance {
  &__container {
    width: 25vw;
    position: absolute;
    right: 0px;
    top: 0px;
    background: #f9fafb;
    border-radius: 8px;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
  }
  &__title {
    font-size: 16px;
    font-weight: 400;
    color: #101828;
    margin: 16px 0px 0px 16px;
  }
  &__count {
    font-size: 50px;
    font-weight: 400;
    color: #000000;
    margin-left: 16px;
  }
  &__exchange {
    display: flex;
    align-items: center;
    font-size: 16px;
    color: #344054;
    margin-left: 16px;
    margin-bottom: 20px;
  }
  &__info {
    display: flex;
    justify-content: space-between;
    background-color: #eaecf0;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  &__bitcoin {
    display: flex;
    flex-direction: column;
    padding: 10px 20px;
  }
  &__chart {
    display: flex;
    flex-direction: column;
    padding: 10px 20px;
    img {
      width: 44px;
      margin-right: 5px;
    }
  }

  &__subtitle {
    font-size: 10px;
    color: #475467;
  }

  &__price {
    font-weight: 500;
    font-size: 14px;
    color: #475467;
  }
  &__percentage {
    font-weight: 500;
    font-size: 12px;
    color: #039855;
    &__value {
      margin-left: 5px;
    }
  }
  &__percentage__down {
    font-weight: 500;
    font-size: 12px;
    color: red;
    svg {
      transform: scaleX(-1);
      margin-right: 5px;
      path {
        stroke: red;
      }
    }
  }
}
