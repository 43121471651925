.list {
  list-style: none;

  .title {
    color: var(--color-grey-5);
    font-weight: 500;
  }

  li {
    display: grid;
    align-items: center;
    grid-template-columns:
      60px minmax(130px, 2fr) minmax(280px, 3fr) minmax(120px, 2fr)
      minmax(120px, 2fr) minmax(120px, 2fr) 150px;
    font-size: 12px;
    font-weight: 600;
    color: var(--color-grey-7);
    padding: 8px;
    border-bottom: 2px solid var(--color-grey-2);
    border-radius: 4px;

    &:last-child {
      border: none;
    }
  }
}

.col {
  display: flex;
  align-items: center;

  .userImage {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    margin-right: 8px;
    background-color: var(--color-grey-2);
  }

  &.cardNumber {
    svg {
      margin-right: 5px;
      max-width: 34px;
      max-height: 24px;
    }
  }

  &.invoiceLink {
    a {
      text-decoration: none;
      color: var(--color-primary-5);
    }
  }
}
