.wrapper {
  .downloadMessage {
    text-align: center;
    font-size: 14px;
    color: var(--color-grey-6);
  }

  .secretWordsWrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 32px;

    .blur {
      width: 100%;
      height: 100%;
      position: absolute;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      top: 0;
      cursor: pointer;
      z-index: 1;

      .lockWrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 32px;
        height: 32px;
        background-color: var(--color-primary-1);
        border-radius: 50%;
        box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05), 0 0 0 4px rgba(191, 217, 254, 0.4);
        margin-bottom: 16px;
      }

      .clickHere {
        font-size: 16px;
        font-weight: 600;
        text-decoration: underline;
      }
    }

    .wordsList {
      display: flex;
      align-content: flex-start;
      gap: 16px;
      flex-wrap: wrap;
      width: 100%;
      max-width: 368px;

      &.wordsListHidden {
        filter: blur(4px);
      }

      li {
        font-size: 12px;
        list-style: none;
        color: var(--color-primary-8);
        background-color: var(--color-blue-05);
        width: 112px;
        height: 38px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;

        &.sortableChosen {
          transition: 0.3s;
          transform: rotate(-10deg);
        }

        &.sortableGhost {
          transform: none;
          border: 1px dashed var(--color-primary-2);
          background-color: #fff;
        }
      }
    }

    .quizField {
      width: 100%;
      min-height: 200px;
      height: 100%;
      border-radius: 8px;
      border: 1px solid var(--color-grey-2);
      margin-bottom: 20px;
      box-sizing: content-box;
      padding: 10px;
    }
  }

  .info {
    font-size: 14px;
    text-align: center;
    color: var(--color-grey-6);
    margin-top: 32px;
  }

  .buttonsWrapper {
    margin-top: 32px;
    width: 100%;
    display: flex;
    justify-content: space-between;

    button {
      width: calc(50% - 8px);
    }
  }

  .backButton {
    position: absolute;
    top: 40px;
    left: 40px;

    button {
      display: flex;
      color: var(--color-grey-6);

      svg {
        margin-right: 8px;
        path {
          fill: var(--color-grey-5);
          stroke: var(--color-grey-5);
        }
      }
    }
  }
}
