.analytics {
  width: 48%;
  display: flex;
  border: 1px solid #eaecf0;
  border-radius: 8px;
  font-family: 'Poppins', sans-serif;
  padding: 10px 10px;
  &__icon {
    width: 32px;
    height: 32px;
  }
  &__content {
    display: flex;
    flex-direction: row;
    margin-left: 10px;
    align-items: center;
  }
  &__subtitle {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    display: flex;
    color: #475467;
    margin-left: 10px;
  }
  &__value {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    color: #101828;
  }
  &__bitcoin {
    font-weight: 500;
    font-size: 14px;
    color: #98a2b3;
    margin-left: 10px;
    display: flex;
    align-items: center;
    img {
      width: 16px;
      height: 16px;
    }
    span {
      margin-left: 5px;
      line-height: 18px;
    }
  }
}
