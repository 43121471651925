.main {
  position: relative;
  display: flex;
  height: 100%;

  .page {
    width: 100%;
    overflow: auto;
  }
  .feedbackButton {
    position: absolute;
    bottom: 40px;
    right: 60px;
    color: var(--color-grey-5);
  }
}
