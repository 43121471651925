.page {
  padding: 30px;
  height: 100vh;

  h2 {
    font-size: 32px;
    font-weight: 500;
    margin-bottom: 24px;
  }

  .settingsBlock {
    background-color: var(--color-grey-05);
    width: 100%;
    padding: 16px;
    border-radius: 16px;

    &:not(:last-child) {
      margin-bottom: 24px;
    }

    .radioGroup {
      display: flex;
      gap: 16px;
    }

    .chooser {
      display: flex;
      align-items: center;
      gap: 50px;
    }

    .input {
      max-width: 283px;
    }

    h3 {
      font-size: 24px;
      font-weight: 500;
    }

    .inputs {
      display: flex;

      .input {
        min-width: 283px;

        &:not(:last-child) {
          margin-right: 16px;
        }
      }
    }
  }
}

.infoMark {
  svg {
    margin-left: 7px;
    width: 16px;
    height: 16px;

    path {
      fill: #4d94fc;
    }
  }
}
