.posts {
  &__footer {
    &__like {
      display: flex;
      margin-right: 25px;
      cursor: pointer;
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      color: #667085;
      img {
        margin-right: 10px;
      }
      span {
        margin-left: 5px;
        align-self: center;
      }
    }
  }
}
