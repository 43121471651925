@import 'node_modules/react-datepicker/src/stylesheets/datepicker.scss';

.react-datepicker-label {
  font-size: 12px;
  color: var(--color-grey-5);
}

.react-datepicker__input-container {
  input {
    width: 100%;
    padding: 8px;
    border-radius: 4px;
    border: 1px solid var(--color-grey-2);
    outline: none;
    font-size: 12px;

    &::placeholder{
      color: var(--color-grey-4);
    }

    &:focus {
      border: 1px solid var(--color-primary-4);
    }
  }
}

.react-datepicker-popper {
  z-index: 3;
}

.react-datepicker__header {
  background: none;
  border-bottom: none;
}
.react-datepicker__day-name {
  color: var(--color-grey-5);
  font-size: 14px;
  font-weight: 600;
  width: 34px;
  line-height: 34px;
}

.react-datepicker {
  font-size: 14px;
  font-weight: 400;
  padding: 24px;
  border: none;
  border-radius: 8px;
  box-shadow: 0 8px 32px rgba(91, 112, 133, 0.35);
}

.react-datepicker__day--selected {
  background-color: var(--color-primary-5);
  font-weight: 400;
}
.react-datepicker__day--keyboard-selected {
  background-color: var(--color-primary-5);
}

.react-datepicker__day {
  width: 34px;
  line-height: 34px;
}

.react-datepicker__header--custom {
  select{
    height: 100%;
    padding: 8px;
    border-radius: 4px;
    border: 1px solid var(--color-grey-2);
    transition: .2s;
    cursor: pointer;
    outline-color: var(--color-primary-2);

    &:hover{
      border: 1px solid var(--color-grey-3);
    }
  }
}
