.wrapper {
  display: flex;
  width: 100vw;
  height: 100vh;

  .left {
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    width: 50%;
    height: 100%;
    padding: 140px 16px 16px 16px;
    align-items: center;
    overflow: auto;

    @media screen and (max-width: 1000px){
      width: 100%;
      padding: 80px 16px 16px 16px;
    }
  }

  .right {
    width: 50%;
    height: 100%;
    color: var(--color-grey-8);
    background: url('../../assets/images/signup-backgroundNew.png') bottom no-repeat var(--color-grey-05);
    background-size: contain;
    padding: 20px;
    position: relative;

    @media screen and (max-width: 1000px ){
      display: none;
    }

    .rightText {
      text-align: center;

      h2 {
        margin-top: 120px;
        font-size: 50px;
        font-weight: 300;
        margin-bottom: 16px;

        span {
          color: red;
          font-weight: 700;
          background: linear-gradient(90deg, #0066FB, #12B76A);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
    }
  }
}
