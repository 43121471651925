.wrapper {
  background-color: var(--color-grey-05);
  border-radius: 16px;
  padding: 16px;
  position: relative;
}

.saveButtons {
  display: flex;
  position: absolute;
  top: 16px;
  right: 16px;

  button {
    &:first-child {
      margin-right: 16px;
    }
  }
}

.contentWrapper {
  max-width: 1100px;

  .buttonsWrapper {
    display: flex;
    justify-content: flex-end;
    min-width: 500px;

    button {
      &:first-child {
        margin-right: 16px;
      }
    }
  }
}

.titleWrapper {
  margin-bottom: 24px;

  h3 {
    font-size: 16px;
    font-weight: 500;
  }
  p {
    font-size: 14px;
    color: var(--color-grey-5);
  }
}

.secondaryTitleWrapper {
  margin-bottom: 24px;
  display: flex;

  h4 {
    font-weight: 500;
    font-size: 14px;
  }
  p {
    font-size: 14px;
    color: var(--color-grey-5);
  }

  .textWrapper {
    margin-right: 50px;
  }
}

.inputWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;

  .nameFieldsWrapper {
    display: flex;
  }

  &:first-child {
    .fieldWrapper {
      min-width: 242px;

      &:last-child {
        margin-left: 16px;
      }
    }
  }

  .fieldWrapper {
    min-width: 500px;
  }

  h4 {
    font-size: 14px;
    font-weight: 500;
    color: var(--color-grey-8);
    min-width: 110px;
  }
}

.leftOffset {
  min-width: 110px;
}

.inputWrapper_buttons {
  margin-bottom: 0;
}

.modalButtons {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
}
