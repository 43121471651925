.wrapper {
  display: flex;
  flex-direction: column;
  width: 70%;
  max-width: 400px;

  &.wrapperWide {
    width: 77%;
    max-width: 520px;
  }

  .form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .formItem {
      margin-bottom: 16px;
      max-width: 122px;
    }

    .buttons {
      display: flex;
      width: 100%;
      justify-content: space-between;

      button {
        width: calc(50% - 8px);
      }
    }
  }

  .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-bottom: 16px;

    h2 {
      font-weight: 700;
      margin-bottom: 10px;
    }

    img {
      margin-bottom: 24px;
    }

    p {
      font-size: 16px;
      color: var(--color-grey-5);
    }
  }

  .walletButtons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 32px;

    .walletButton {
      padding: 16px;
      border-radius: 8px;
      border: 1px solid var(--color-grey-1);
      text-align: center;
      cursor: pointer;
      min-width: 244px;

      &:hover {
        border: 1px solid var(--color-primary-1);
        background-color: var(--color-grey-05);
      }

      svg {
        margin-bottom: 20px;
      }

      h3 {
        font-weight: 500;
        font-size: 14px;
        margin-bottom: 12px;
      }

      p {
        font-size: 12px;
        color: var(--color-grey-5);
      }
    }
  }

  .phraseForm {
    p {
      display: flex;
      align-items: center;
      margin: 16px 0;
      font-size: 12px;
      color: var(--color-grey-5);

      svg {
        margin-right: 10px;
      }
    }
  }
}
