.dropzoneWrapper {
  display: flex;
  min-height: 130px;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 16px;
  margin-bottom: 10px;
  border: 1px solid var(--color-grey-2);
  border-radius: 8px;
  background-color: #fff;
  color: var(--color-grey-7);
  cursor: pointer;
  transition: .3s;

  .uploadButton {
    display: flex;
    align-items: center;
    flex-direction: column;

    p {
      font-size: 14px;
      color: var(--color-grey-7);
      transition: .3s;

      &:not(:last-child) {
        margin-bottom: 10px;
      }

      span {
        font-weight: 500;
        color: var(--color-primary-6);
      }
    }

    .cloudWrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05), 0 0 0 4px rgba(245, 247, 250, 0.85);
      background-color: var(--color-grey-2);
      margin-bottom: 16px;
      transition: .3s;

      svg {
        path {
          stroke: var(--color-grey-7);
        }
      }
    }
  }

  &:hover {
    background-color: var(--color-primary-025);
    border-color: var(--color-primary-05);

    .changeImage {
      display: flex;
      margin-left: auto;
    }

    .uploadButton {

      p{
        color: var(--color-primary-5);
      }
      .cloudWrapper {
        box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05), 0 0 0 4px rgba(191, 217, 254, 0.4);
        background-color: var(--color-primary-1);

        svg {
          path {
            stroke: var(--color-primary-6);
          }
        }
      }
    }
  }

  .changeImage {
    display: none;
    transition: 0.3s;

    .cloudWrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05), 0 0 0 4px rgba(191, 217, 254, 0.4);
      background-color: var(--color-primary-1);
      margin-right: 10px;
    }

    .trashWrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05), 0 0 0 4px rgba(254, 228, 226, 0.4);
      background-color: var(--color-red-1);
      cursor: pointer;

      svg {
        path {
          stroke: var(--color-red-5);
          transition: 0.3s;
        }
      }

      &:hover {
        svg {
          path {
            stroke: var(--color-red-5);
          }
        }
      }
    }
  }
}
