.chart {
  height: 35vh;
  width: 50%;
  position: relative;
  &__title {
    z-index: 111;
    position: absolute;
    left: 100px;
    top: 20px;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #101828;
  }
  &__button {
    display: flex;
    border: 1px solid #267dfc;
    border-radius: 4px;
    cursor: pointer;
    z-index: 111;
    position: absolute;
    right: 50px;
    top: 20px;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #475467;
    &__line {
      padding: 2px 10px;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }
    &__bar {
      padding: 2px 10px;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }
}
