.create__post {
  background: #ca33ff;
  height: auto;
  padding: 20px 30px 85px;
  position: relative;

  .accenture {
    font-family: 'Poppins', sans-serif;
    font-size: 24px;
    font-weight: bold;
    color: #000000;
  }

  .title {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 50px;
    color: #ffffff;
    &__text {
      display: inline-flex;
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
    }
    &__img {
      width: 20px;
      height: 20px;
      align-self: center;
    }
  }
}

.container {
  position: relative;
}

.logo {
  display: flex;
  width: 150px;
  height: auto;
}

.main {
  &__content {
    display: flex;
    justify-content: space-between;
  }
}

.posts {
  &__wrapper {
    width: 65.5%;
  }
  &__empty {
    display: flex;
    width: 65.5%;
    padding: 20px;
    justify-content: center;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    text-align: center;
    color: #101828;
  }
}

.anniversaries {
  margin-left: 35px;
  margin-top: 30px;
  margin-right: 30px;
  &__wrapper {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  &__line {
    width: 65%;
    height: 1px;
    background-color: #eaecf0;
  }
  &__title {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #344054;
  }
}

.home {
  &__wrapper {
    width: 65%;
    justify-content: space-between;
    display: flex;
    margin-top: 55px;
    align-items: center;
    margin-bottom: -55px;
  }
  &__line {
    width: 85%;
    height: 1px;
    background-color: #eaecf0;
  }
}

.post {
  &__select {
    &__option {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      display: flex;
      align-items: center;
      text-align: center;
      color: #344054;
      margin-left: 10px;
    }
  }
}
