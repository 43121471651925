.avatarWrapper {
  position: relative;
  width: 100px;
  height: 100px;
  background-color: var(--color-primary-025);
  background-size: cover;
  border-radius: 50%;
  border: 1px solid var(--color-primary-05);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s;

  &:hover {
    border: 1px solid var(--color-primary-05) !important;

    .changeAvatar {
      display: initial;
    }
  }

  .uploadButton {
    display: flex;
    flex-direction: column;
    align-items: center;

    .cloudWrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05), 0 0 0 4px rgba(191, 217, 254, 0.4);
      background-color: var(--color-primary-1);
    }

    p {
      margin-top: 5px;
      font-size: 10px;
      font-weight: 500;
      color: var(--color-grey-8);
    }
  }

  .changeAvatar {
    display: none;
    transition: 0.3s;

    .cloudWrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05), 0 0 0 4px rgba(191, 217, 254, 0.4);
      background-color: var(--color-primary-1);
    }

    .trashWrapper {
      width: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      bottom: -8px;

      svg {
        path {
          transition: 0.3s;
        }
      }

      &:hover {
        svg {
          path {
            stroke: var(--color-red-5);
          }
        }
      }
    }
  }
}
